'use strict';

var braintreeCreditCardSDK = require('./braintreeCreditCardSDK');
var braintreeGeneral = require('../../braintreeGeneral');
var creditCardFormFieldHelper = require('../helpers/creditCardFormFieldsHelper');

var braintreeCreditCardSdkHelper = require('../helpers/braintreeCreditCardSdkHelper');

/* global Promise braintree $ */

// global variables
var hostedFieldsInstance;
var btClientInstancePromise;
var orderData;
var sitePrefs = {};

/**
 * Init Braintree Credit Card model
 * @param {Promise} braintreeClientInstancePromise Braintreee client instance promise
 * @param {boolean} isSkip3dSecureLiabilityResult 'BRAINTREE_3DSecure_Skip_Client_Validation_Result' site preference value
 * @param {boolean} is3dSecureEnabled 'BRAINTREE_3DSecure_Enabled' site preference value
 * @param {bollean} isFraudToolsEnabled Is fraud tools enabled value
 */
function init(braintreeClientInstancePromise, isSkip3dSecureLiabilityResult, is3dSecureEnabled, isFraudToolsEnabled) {
    braintreeCreditCardSDK.init(braintreeClientInstancePromise);

    btClientInstancePromise = braintreeClientInstancePromise;
    sitePrefs = {
        isSkip3dSecureLiabilityResult: isSkip3dSecureLiabilityResult,
        is3dSecureEnabled: is3dSecureEnabled,
        isFraudToolsEnabled: isFraudToolsEnabled
    };
}


/**
 * Gets required additional shipping info for 3ds
 *
 * @param {Object} orderAddress - User's shipping address
 * @returns {Object} an object with required fields
 */

function getShippingAdditionalInfo(orderAddress) {
    return {
        workPhoneNumber: orderAddress.phone,
        shippingGivenName: orderAddress.recipientName.split(' ').slice(0, -1).join(' '),
        shippingSurname: orderAddress.recipientName.split(' ').slice(-1).join(' '),
        shippingPhone: orderAddress.phone,
        shippingAddress: {
            streetAddress: orderAddress.line1,
            extendedAddress: orderAddress.line2,
            locality: orderAddress.city,
            region: orderAddress.state,
            postalCode: orderAddress.postalCode,
            countryCodeAlpha2: orderAddress.countryCode
        }
    };
}

/**
 * Updates Order data on Checkout
 */
function updateOrderData() {
    $.ajax({
        url: document.querySelector('.js_braintree_getOrderInfoUrl').value + '?qwe=2344',
        type: 'get',
        dataType: 'json',
        success: function (data) {
            data.shippingAdditionalInfo = data.shippingAddress ? getShippingAdditionalInfo(data.shippingAddress) : null;
            orderData = data;
        },
        error: function () {
            window.location.reload();
        }
    });
}

/**
 * Returns fraud data from dataCollector payload
 * @returns {Object} Payload with fraud data
 */
function collectFraudData() {
    var response;

    if (sitePrefs && sitePrefs.isFraudToolsEnabled) {
        response = braintreeGeneral.collectFraudData(btClientInstancePromise);
    } else {
        response = Promise.resolve({
            customMessage: 'Fraud Data collection isn\t enabled via Custom Site Preference'
        });
    }

    return response;
}

/**
 * Inits Credit Card hosted fields
 * @returns {Object} Hosted Fields instance
 */
function initCreditCardFields() {
    var hostedFieldsParams = null;
    var styles = creditCardFormFieldHelper.getHostedFieldsStyles();
    var configs = creditCardFormFieldHelper.getHostedFieldsConfig();

    hostedFieldsParams = {
        styles,
        configs
    };

    return braintreeCreditCardSDK.createHostedFields(hostedFieldsParams)
        .then(function (hfInstance) {
            // init hosted fields instance globaly
            hostedFieldsInstance = hfInstance;

            return hfInstance;
        });
}

/**
 * Creates Credit Card hosted fields
 */
function clearHostedFields() {
    braintreeCreditCardSDK.clearHostedFields(hostedFieldsInstance);
}

// eslint-disable-next-line valid-jsdoc
/**
 * Tokenizes previouselly filled hosted Credit Card fields and returns a nonce payload
 * @returns {Promise} Promise with { error: false, result: 'ok'} inside in case of positive response and
 * { error: true} in case of negative (reject, case)
 */
function tokenize(isCheckoutPage) {
    var tokenizationOptions;

    // "Hosted Field Instance" validation
    if (!hostedFieldsInstance) {
        return Promise.reject({
            customError: '"hostedFieldsInstance" is not define. Tokenization can\'t be done'
        });
    }

    if (isCheckoutPage) {
        // Pull billing address from Storefront. Passed data will be tokenized and sent to Braintree
        tokenizationOptions = braintreeCreditCardSdkHelper.createTokenizationOptions();
    } else {
        tokenizationOptions = {};
    }

    return braintreeCreditCardSDK.tokenize(hostedFieldsInstance, tokenizationOptions)
        .then(function (payload) {
            return {
                btTokenizePayload: payload
            };
        });
}

/**
 * Launch the 3D Secure login flow, returning a nonce payload.
 * @param {string} nonce Payment method nonce
 * @param {string} bin Payment method bin
 * @param {number} amount Total amount
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {Object} shippingAdditionalInfo Object with shipping info
 * @param {Object} btTokenizePayload Tokenize payload
 * @returns {Object} Object with nonce payload or custom error
 */
function apply3dSecureValidation(nonce, bin, amount, email, billingData, shippingAdditionalInfo, btTokenizePayload) {
    return braintreeCreditCardSDK.apply3dSecureValidation(nonce, bin, amount, email, billingData, shippingAdditionalInfo)
        .then(function (payload) {
            if (payload.liabilityShifted || sitePrefs.isSkip3dSecureLiabilityResult) {
                // "Nonce" is changed after successful 3DS validation. We need this line to store the last "nonce" value
                if (btTokenizePayload) {
                    // For stored card use case we don't need this param
                    btTokenizePayload.nonce = payload.nonce;
                }

                return {
                    threeDSecureDataValidationPayload: payload,
                    btTokenizePayload: btTokenizePayload
                };
            }

            // Case when buyer "canceled" 3DS window
            return Promise.reject({
                customError: '3DS popup was canceled by buyer'
            });
        });
}

/**
 * Process the new Credit Card
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {boolean} isCheckoutPage Is checkout flow flag
 * @returns {Object} Object with nonce payload or custom error
 */
function processNewCard(email, billingData, isCheckoutPage) {
    var is3dSecureDisabled = sitePrefs && !sitePrefs.is3dSecureEnabled;
    var orderAmount = orderData.amount;
    var shippingAdditionalInfo = orderData.shippingAdditionalInfo;
    var nonce = null;
    var bin = null;
    var result = null;

    result = tokenize(isCheckoutPage);

    if (!is3dSecureDisabled) {
        result = result
            .then(function (payload) {
                nonce = payload.btTokenizePayload.nonce;
                bin = payload.btTokenizePayload.details ? payload.btTokenizePayload.details.bin : '';

                return apply3dSecureValidation(
                    nonce,
                    bin,
                    orderAmount,
                    email,
                    billingData,
                    shippingAdditionalInfo,
                    payload.btTokenizePayload
                );
            });
    }

    return result;
}

/**
 * Process the stored Credit Card
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {string} nonce Payment method nonce
 * @param {string} bin Payment method bin
 * @returns {Object} Object with nonce payload or custom error
 */
function processStoredCard(email, billingData, nonce, bin) {
    var orderAmount = orderData.amount;
    var shippingAdditionalInfo = orderData.shippingAdditionalInfo;
    var is3dSecureDisabled = sitePrefs && !sitePrefs.is3dSecureEnabled;
    var result = null;

    // Case when we have a nonce, it is the case when buyer use stored credit card
    // otherwise, it is the case when buyer use new credit card
    if (is3dSecureDisabled) {
        result = Promise.resolve({
            nonce,
            email,
            bin
        });
    } else {
        result = apply3dSecureValidation(
            nonce,
            bin,
            orderAmount,
            email,
            billingData,
            shippingAdditionalInfo
        );
    }

    return result;
}

/**
 * In case of session card we can do nothing since the nonce, 3ds, hosted fields validation already was passed
 * @returns {Promise} return Promise with success data
 */
function processSessionCard() {
    return Promise.resolve({});
}

/**
 * Checks whether the basket is valid
 * @param {string} errorMessage Error message
 * @returns {Object} response object
 */
function isBasketDataValid(errorMessage) {
    var response = {
        error: false,
        customErrorMessage: ''
    };

    if (!orderData || orderData.amount === 0) {
        response.error = true;
        response.customErrorMessage = errorMessage;
    }

    return response;
}

/**
 * Get BT client instance which is used currently in the model
 * @returns {Promise} BT client instance promise
 */
function getClientInstancePromise() {
    return btClientInstancePromise;
}

module.exports = {
    init,
    // "tokenize" or "processNewCard", or "processStoredCard", or "clearHostedFields"
    // can be called only after excution of "initCreditCardFields"
    tokenize,
    initCreditCardFields,
    processNewCard,
    processStoredCard,
    processSessionCard,
    clearHostedFields,
    getClientInstancePromise,

    // "isBasketDataValid" can be called only after calling of "updateOrderData"
    isBasketDataValid,
    updateOrderData,

    collectFraudData
};
