'use strict';

var helper = require('../../helper');
var creditCardHelper = require('./creditCardHelper');
var CreditCardVaultManagerModel = require('../components/creditCardVaultManagerModel');

/**
 * Displays stores Credit Cards list on the Billing Page
 */
function displayStoredCreditCardList() {
    var $creditCardAccount = document.querySelector('.form-group.braintree_used_creditcard_account');

    $creditCardAccount.classList.remove('used-creditcard-account-hide');
    $creditCardAccount.classList.add('used-creditcard-account');
}

/**
 * Fills the Credit Card form hidden inputs with payload data
 * @param {Object} braintreeResponse Object contains the data of new payment method
 */
function fillCreditCardFormWithPayloadData(braintreeResponse) {
    var btResponse = braintreeResponse.btTokenizePayload;
    var $creditCardFieldsCardNumber = document.querySelector('input[name=dwfrm_billing_creditCardFields_cardNumber]');

    if (btResponse) {
        var btResponseDetails = btResponse.details;

        document.querySelector('#braintreeCardType').value = creditCardHelper.convertCreditCardType(btResponseDetails.cardType);
        document.querySelector('#braintreeCardMaskNumber').value = '************' + btResponseDetails.lastFour;
        document.querySelector('#braintreeCardExpirationMonth').value = btResponseDetails.expirationMonth;
        document.querySelector('#braintreeCardExpirationYear').value = btResponseDetails.expirationYear.substr(2);
        document.querySelector('#braintreeCardHolder').value = btResponseDetails.cardholderName;

        if ($creditCardFieldsCardNumber) {
            $creditCardFieldsCardNumber.value = '************' + btResponseDetails.lastFour;
        }

        document.querySelector('input[name=braintreePaymentMethodNonce]').value = btResponse.nonce;
    }
}

/**
 * Fills the session Credit Card form inputs with payload data
 * @param {Object} braintreeResponse Object contains the data of new payment method
 */
function fillSessionCreditCardFormWithPayloadData(braintreeResponse) {
    var btResponse = braintreeResponse.btTokenizePayload;
    var usedCreditCardAccount = Boolean(document.querySelector('.form-group.braintree_used_creditcard_account'));

    if (usedCreditCardAccount && btResponse) {
        var btResponseDetails = btResponse.details;
        var $sessionCreditCard = document.querySelector('#braintreeSessionCreditAccount');

        // Fields filling for displaying session card fields to the buyer
        document.querySelector('#braintreeCardOwnerToDisplay').textContent = btResponseDetails.cardholderName;
        document.querySelector('#braintreeCardNumberToDisplay').textContent = '************' + btResponseDetails.lastFour;
        document.querySelector('#braintreeCvvToDisplay').textContent = '***';
        document.querySelector('#braintreeExpirationToDisplay').textContent = btResponseDetails.expirationMonth + '/' + btResponseDetails.expirationYear.substr(2);

        // Fields filling for displaying session card option value
        $sessionCreditCard.textContent = $sessionCreditCard.getAttribute('data-type') + ' ' +
            $sessionCreditCard.getAttribute('data-number') + ' ' +
            $sessionCreditCard.getAttribute('data-expiration') + ' ' +
            $sessionCreditCard.getAttribute('data-owner');
    }
}

/**
 * Filling of session card attributes for using them in business logic (client/server)
 * @param {Object} braintreeResponse Tokenize payload from braintree
 */
function setSessionCreditCardDataAttributes(braintreeResponse) {
    var btResponse = braintreeResponse.btTokenizePayload;
    var btResponseDetails = btResponse.details;
    var $sessionCreditCard = document.querySelector('#braintreeSessionCreditAccount');
    var isSavedCard = document.querySelector('#braintreeSaveCreditCard') ? document.querySelector('#braintreeSaveCreditCard').checked : false;

    $sessionCreditCard.classList.remove('used-creditcard-account-hide');
    $sessionCreditCard.setAttribute('data-number', '************' + btResponseDetails.lastFour);
    $sessionCreditCard.setAttribute('data-expiration', btResponseDetails.expirationMonth + '/' + btResponseDetails.expirationYear.substr(2));
    $sessionCreditCard.setAttribute('data-type', creditCardHelper.convertCreditCardType(btResponseDetails.cardType));
    $sessionCreditCard.setAttribute('data-last-four', btResponseDetails.lastFour);
    $sessionCreditCard.setAttribute('data-owner', btResponseDetails.cardholderName);
    $sessionCreditCard.setAttribute('data-nonce', btResponse.nonce);
    $sessionCreditCard.setAttribute('data-session-account', true);
    $sessionCreditCard.setAttribute('data-save-card', isSavedCard);
}

/**
 * Marks a session Credit Card account as selected
 */
function markAsSelectedSessionAccount() {
    var $newCCAccount = document.getElementById('newCardAccount');
    var $sessionCreditCard = document.querySelector('#braintreeSessionCreditAccount');

    $newCCAccount.removeAttribute('selected');
    $sessionCreditCard.selected = true;
}

/**
 * Returns a Credit Card flow ID from selector
 * @param {Object} $creditCardList Selector contained the Credit Card flow ID
 * @returns {string} Credit Card flow ID
 */
function getCreditCardFlowID($creditCardList) {
    var selectedOptionValue = $creditCardList.value;
    var selectedOption = null;
    var creditCardFlowID = null;

    var newCardID = 'newcard';
    var sessionCardID = 'sessioncard';
    var storedCardID = 'js_stored_card';

    switch (selectedOptionValue) {
        case newCardID:
            creditCardFlowID = newCardID;
            break;

        case sessionCardID:
            creditCardFlowID = sessionCardID;
            break;

        default:
            selectedOption = helper.getSelectedOption($creditCardList);

            if (selectedOption.classList.contains(storedCardID)) {
                creditCardFlowID = storedCardID;
            }
            break;
    }

    return creditCardFlowID;
}

/**
 * Check Validity of Card Expiry Date
 * @param {string} expirationDate Expiry Date of Card
 * @returns {boolean} true in case if stored card has valid expiry date
 */
function isCardExpired(expirationDate) {
    var parts = expirationDate.split('/');
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[1], 10);

    var today = new Date();
    var currentMonth = today.getMonth() + 1; // JavaScript months are 0-based
    var currentYear = today.getFullYear().toString();
    if (year && $.trim(year).length === 2) {
        currentYear = currentYear.slice(-2);
    }
    currentYear = parseInt(currentYear, 10);
    currentMonth = parseInt(currentMonth, 10);
    // Check if the card is expired
    return (year < currentYear) || (year === currentYear && month < currentMonth);
}

/**
 * Returns object with nonce related to stored card
 * @param {Promise} clientInstancePromise Client instance promise
 * @param {Object} $creditCardList Selector with needed data
 * @returns {Object} Response object
 */
function getNonceFromStoredCard(clientInstancePromise, $creditCardList) {
    var $selectedOption = helper.getSelectedOption($creditCardList);
    var expirationDate = $selectedOption.getAttribute('data-expiration').toString();
    if (isCardExpired(expirationDate)) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return Promise.reject(new Error('Your saved payment method was invalid. Please try a new payment method or call for assistance at 866-344-3875.'));
    }
    var CreditCardVaultManagerModelInstance = new CreditCardVaultManagerModel(clientInstancePromise);
    var cardType = $selectedOption.getAttribute('data-type').toLowerCase();
    var lastFour = $selectedOption.getAttribute('data-last-four').toString();
    return CreditCardVaultManagerModelInstance.getNonceByCardData(lastFour, cardType);
}

/**
 * Fills 3DS data
 * @param {Object} threeDSecureDataValidationPayload 3DS information about the card
 */
function fill3DsData(threeDSecureDataValidationPayload) {
    var $braintreeIs3dSecureRequired = document.querySelector('#braintreeIs3dSecureRequired');

    if (threeDSecureDataValidationPayload && threeDSecureDataValidationPayload.liabilityShifted) {
        $braintreeIs3dSecureRequired.value = 'true';
    }
}

/**
 * Returns whether Credit Card tab is active on the Billing Page
 * @returns {boolean} true/false
 */
function isActiveCreditCardTab() {
    return document
        .querySelector('.payment-options[role=tablist] a[data-toggle="tab"][href="#creditcard-content"]')
        .classList
        .contains('active');
}

/**
 * Get stored card by last four digits and card type
 * @param {string} lastFour Last four card digits
 * @param {string} cardType Card type
 * @returns {Object} Jquery object of found stored card
 */
function getStoredCardByData(lastFour, cardType) {
    var $foundCard;

    $('.js_stored_card').each(function (_, $el) {
        var $card = $($el);
        var storedCardType = $card.data('type').toLowerCase();
        var storedCardLastFour = $card.data('last-four').toString();

        if (storedCardType === cardType && storedCardLastFour === lastFour) {
            $foundCard = $card;
        }
    });

    return $foundCard;
}

/**
 * Hide already stored card by last four digits and card type
 * @param {string} lastFour Last four card digits
 * @param {string} cardType Card type
 * @returns {boolean} true in case if stored card with provided params (lastFour, cardType) was found
 */
function hideAlreadyStoredCard(lastFour, cardType) {
    var $storedCard = getStoredCardByData(lastFour, cardType);
    var flag = false;

    if ($storedCard) {
        $storedCard.hide();
        flag = true;
    }

    return flag;
}

/**
 * Shows already stored card by last four digits and card type
 * @param {string} lastFour Last four card digits
 * @param {string} cardType Card type
 * @returns {boolean} true in case if stored card with provided params (lastFour, cardType) was found
 */
function showAlreadyStoredCard(lastFour, cardType) {
    var $storedCard = getStoredCardByData(lastFour, cardType);
    var flag = false;

    if ($storedCard) {
        $storedCard.show();
        flag = true;
    }

    return flag;
}

module.exports = {
    displayStoredCreditCardList,
    fillCreditCardFormWithPayloadData,
    fillSessionCreditCardFormWithPayloadData,
    setSessionCreditCardDataAttributes,
    markAsSelectedSessionAccount,
    getCreditCardFlowID,
    getNonceFromStoredCard,
    fill3DsData,
    isActiveCreditCardTab,
    hideAlreadyStoredCard,
    showAlreadyStoredCard
};
